<template>
  <header
    v-if="pageSettings.header"
    :class="{
      'cwp-header--sticky': sticky,
      'cwp-header--sticky-enter': stickyEnter,
      'cwp-header--sticky-leave': stickyLeave,
      'cwp-header--expanded': expanded,
      'cwp-header--slim': page.isSlimPage,
      'cwp-header--search-active': search,
    }"
    class="cwp-header"
  >
    <div class="cwp-header__top grid-container">
      <div class="cwp-header__mobile-tel-link">
        <cwp-link
          v-if="pageSettings.projectDetails.phoneNumber && !hidePhoneNumber"
          primary
          :href="`tel:${pageSettings.projectDetails.phoneNumber}`"
          @click="$fbq.track('Contact')"
        >
          <cwp-icon interactive primary name="phone.svg" />
        </cwp-link>
      </div>
      <div class="cwp-header__logo-container">
        <cwp-link
          :to="pageSettings.header.projectLogoUrl ? pageSettings.header.projectLogoUrl : `/${projectPath}/`"
          class="cwp-header__logo"
          :deactivated="deactivateLogoLink"
        >
          <img
            v-show="sticky"
            key="slim"
            :src="pageSettings.header.slimProjectLogo || pageSettings.header.projectLogo"
            :alt="pageSettings.header.slimLogoAltText || pageSettings.header.logoAltText"
            class="cwp-header__desktop-logo cwp-header__desktop-logo--slim"
          />
          <img
            v-show="!sticky"
            key="regular"
            :src="pageSettings.header.projectLogo"
            :alt="pageSettings.header.logoAltText"
            class="cwp-header__desktop-logo cwp-header__desktop-logo--regular"
          />
          <img
            key="mobile"
            class="cwp-header__mobile-logo"
            :src="pageSettings.header.projectLogo"
            :alt="pageSettings.header.logoAltText"
          />
        </cwp-link>
        <cwp-link
          v-if="pageSettings.header.logoStyle && !hideCWPLogo"
          class="cwp-header__mobile-logo"
          href="https://www.cedarwoods.com.au/"
          target="_blank"
          :deactivated="deactivateLogoLink"
        >
          <img
            class="cwp-header__mobile-logo"
            :src="`${assetPath}images/cw-logo-${pageSettings.header.logoStyle}.svg`"
            alt="Cedar Woods Logo"
          />
        </cwp-link>
      </div>
      <div class="cwp-header__link-container">
        <div class="cwp-header__sub-logo-container">
          <div v-if="pageSettings.header.jointVenturePartnerLogo" class="cwp-header__sub-logo">
            <cwp-link
              :href="pageSettings.header.jointVenturePartnerLogoUrl"
              target="_blank"
              :deactivated="deactivateLogoLink"
            >
              <img :src="pageSettings.header.jointVenturePartnerLogo" alt="Joint venture partner logo" />
            </cwp-link>
          </div>
          <div v-if="pageSettings.header.logoStyle && !hideCWPLogo" class="cwp-header__sub-logo">
            <cwp-link href="https://www.cedarwoods.com.au/" target="_blank" :deactivated="deactivateLogoLink">
              <img :src="`${assetPath}images/cw-logo-${pageSettings.header.logoStyle}.svg`" alt="Cedar Woods Logo" />
            </cwp-link>
          </div>
        </div>
        <div class="cwp-header__cta-container">
          <div
            v-if="pageSettings.integrations.headerShowSocialIcons && !hideSocialIcons"
            class="cwp-header__social-links"
          >
            <cwp-social-links />
          </div>
          <div v-if="pageSettings.projectDetails.phoneNumber && !hidePhoneNumber" class="cwp-header__tel-link">
            <cwp-link primary :href="`tel:${pageSettings.projectDetails.phoneNumber}`" @click="$fbq.track('Contact')">{{
              pageSettings.projectDetails.phoneNumber
            }}</cwp-link>
          </div>
          <div v-if="pageSettings.header.buttonText && !hideCTAbutton" class="cwp-header__register-link">
            <cwp-link button primary :to="pageSettings.header.buttonUrl">{{ pageSettings.header.buttonText }}</cwp-link>
          </div>
          <div class="cwp-header__navigation-menu">
            <cwp-hamburger v-if="!hideCTAbutton || !hideSocialIcons" v-model="expanded" />
          </div>
        </div>
      </div>
    </div>

    <rv-expansion-panel
      ref="expansionPanelDesktop"
      v-model="expanded"
      class="cwp-header__bottom-wrapper cwp-header__bottom--desktop"
      @input="updateSearch"
    >
      <transition mode="out-in" appear @after-enter="updateVariableNavHeightDesktop">
        <div v-show="expanded" class="cwp-header__bottom cwp-header__bottom--desktop">
          <div class="cwp-header__bottom-inner grid-container">
            <div v-if="pageSettings.header.buttonText && !hideCTAbutton" class="cwp-header__mobile-register-link">
              <cwp-link button primary :to="pageSettings.header.buttonUrl">{{
                pageSettings.header.buttonText
              }}</cwp-link>
            </div>
            <rv-primary-nav
              v-if="!page.isSlimPage"
              :nodes="nodes"
              :hover="breakpoints.large"
              auto-close
              force-all-links-to-open-same-page
            >
              <template #toggle>
                <span class="rv-expansion-panel__chevron" />
              </template>
            </rv-primary-nav>
            <div v-show="!page.isSlimPage">
              <form
                class="cwp-header__search"
                :class="{ 'cwp-header__search--active': search }"
                @submit.prevent="handleSearch"
              >
                <rv-input
                  ref="searchInput"
                  class="cwp-header__search-input"
                  :field="{
                    label: 'Search',
                    id: 'site-search',
                    placeholder: 'Search for anything',
                  }"
                  @input="
                    (formName, fieldName, value) => {
                      searchKeywordValue = value
                    }
                  "
                />
                <cwp-link class="cwp-header__search-submit" button primary tag="button"> Search </cwp-link>
                <cwp-icon
                  v-if="search"
                  interactive
                  class="cwp-header__search-icon"
                  primary
                  name="search.svg"
                  @click="handleSearch"
                />
              </form>
              <cwp-search-button v-model="search" @input="focusSearch" />
            </div>
            <div v-if="pageSettings.integrations.headerShowSocialIcons" class="cwp-header__mobile-social-links">
              <cwp-social-links />
            </div>
          </div>
        </div>
      </transition>
    </rv-expansion-panel>
    <rv-expansion-panel v-model="expanded" class="cwp-header__bottom-wrapper" @input="updateSearch">
      <div v-show="expanded" class="cwp-header__bottom">
        <div class="cwp-header__bottom-inner grid-container">
          <div v-if="pageSettings.header.buttonText && !hideCTAbutton" class="cwp-header__mobile-register-link">
            <cwp-link button primary :to="pageSettings.header.buttonUrl">{{ pageSettings.header.buttonText }}</cwp-link>
          </div>
          <rv-primary-nav
            v-if="!page.isSlimPage"
            :nodes="nodes"
            :hover="breakpoints.large"
            auto-close
            force-all-links-to-open-same-page
          >
            <template #toggle>
              <span class="rv-expansion-panel__chevron" />
            </template>
          </rv-primary-nav>
          <div v-show="!page.isSlimPage">
            <form
              class="cwp-header__search"
              :class="{ 'cwp-header__search--active': search }"
              @submit.prevent="handleSearch"
            >
              <rv-input
                ref="searchInputAlt"
                class="cwp-header__search-input"
                :field="{
                  label: 'Search',
                  id: 'site-search-alt',
                  placeholder: 'Search for anything',
                }"
                @input="
                  (formName, fieldName, value) => {
                    searchKeywordValue = value
                  }
                "
              />
              <cwp-link class="cwp-header__search-submit" button primary tag="button"> Search </cwp-link>
              <cwp-icon
                v-if="search"
                interactive
                class="cwp-header__search-icon"
                primary
                name="search.svg"
                @click="handleSearch"
              />
            </form>
            <cwp-search-button v-model="search" @input="focusSearch" />
          </div>
          <div
            v-if="pageSettings.integrations.headerShowSocialIcons && !hideSocialIcons"
            class="cwp-header__mobile-social-links"
          >
            <cwp-social-links />
          </div>
        </div>
      </div>
    </rv-expansion-panel>
  </header>
</template>

<script>
import { RvInput, RvPrimaryNav, RvExpansionPanel } from '@revium/components'
import CwpSocialLinks from '@/components/CwpSocialLinks/CwpSocialLinks.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpHamburger from '@/components/CwpHamburger/CwpHamburger.vue'
import CwpSearchButton from '@/components/CwpSearchButton/CwpSearchButton.vue'
import { mapState } from 'vuex'
import { throttle } from '@/functions/utils'
export default {
  name: 'CwpHeader',
  components: {
    CwpSocialLinks,
    CwpLink,
    CwpIcon,
    CwpHamburger,
    CwpSearchButton,
    RvInput,
    RvPrimaryNav,
    RvExpansionPanel,
  },
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: true,
      search: false,
      searchKeywordValue: '',
      stickyEnter: false,
      stickyLeave: false,
      assetPath: process.env.VUE_APP_ASSET_PATH,
      projectPath: window.location.pathname.split('/')[1],
      handleScroll: null,
    }
  },
  computed: {
    ...mapState({
      pageSettings: state => state.site.pageSettings,
      page: state => state.site.page,
      breakpoints: state => state.settings.breakpoints,
      modal: state => state.settings.modal,
    }),
    // Limit menu nesting
    nodes() {
      function limitDepth(node, maxDepth = 0, depth = 1) {
        node = Object.assign({}, node)
        node.children =
          node.children && depth < maxDepth
            ? node.children.map(node => {
                return limitDepth(node, maxDepth, depth + 1)
              })
            : []
        return node
      }
      return this.pageSettings.primaryNavigation.map(node => {
        return limitDepth(node, 3)
      })
    },
    searchKeyword() {
      return this.$route.query.q
    },
    navShowSpeed() {
      return 500
    },
    deactivateLogoLink() {
      return this.page.customData?.displayOptions.disableLogoLinks
    },
    hideCWPLogo() {
      return this.page.customData?.displayOptions.hideLogoInHeader
    },
    hideCTAbutton() {
      return this.page.customData?.displayOptions.hideCtaButtonsInHeader
    },
    hidePhoneNumber() {
      return this.page.customData?.displayOptions.hidePhoneNumberInHeader
    },
    hideSocialIcons() {
      return this.page.customData?.displayOptions.hideSocialIconsInHeader
    },
  },
  watch: {
    sticky(newVal) {
      // trigger sticky animations on desktop (auto expanding/collapsing the menu)
      if (this.breakpoints.large) {
        newVal ? (this.stickyEnter = true) : (this.stickyLeave = true)
        setTimeout(() => {
          newVal ? (this.stickyEnter = false) : (this.stickyLeave = false)
        }, this.navShowSpeed)
        this.expanded = !newVal
      }
    },
    'breakpoints.large'(newVal) {
      // If screen resizes to mobile, close menu, otherwise open it
      this.expanded = newVal
    },
  },
  mounted() {
    // If scrolled down, or on mobile, menu should start collapsed
    if (this.sticky || !this.breakpoints.large) this.expanded = false
    // Close menu on navigation events
    this.$router.beforeEach((to, from, next) => {
      if (!this.breakpoints.large) this.expanded = false
      next()
    })
    // Track scroll events to expand/close menu
    let scrollTop = 0
    let staticOffset = 10

    this.handleScroll = throttle(
      ev => {
        // Only on desktop when header is sticky
        if (this.breakpoints.large && this.sticky && !this.modal.open) {
          const newScrollTop = window.pageYOffset || document.documentElement.scrollTop

          // Do nothing if header is already animating (as this affects scroll position)
          if (!this.stickyEnter && !this.stickyLeave) {
            const scrollDistance = newScrollTop - scrollTop

            // Collapse on scroll down events, expand on scroll up events with a 60px buffer
            const collapse = scrollDistance >= this.variableDesktopNavHeight + staticOffset
            const expand = scrollDistance <= -this.variableDesktopNavHeight - staticOffset

            if (collapse) {
              if (this.expanded) this.expanded = false
            } else if (expand) {
              if (!this.expanded) this.expanded = true
            }
          }

          // Update scroll position for next calculation
          scrollTop = newScrollTop
        }
      },
      this.navShowSpeed,
      { trailing: true }
    )
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    focusSearch(active) {
      if (active) {
        this.updateSearch()
        this.$el.querySelector('#site-search').focus()
      }
    },
    updateSearch() {
      this.searchKeywordValue = this.searchKeyword || ''
      if (this.$refs.searchInput) this.$refs.searchInput.fieldValue = this.searchKeyword || ''
    },
    handleSearch() {
      const path = `/${this.projectPath}/search-results?q=${this.searchKeywordValue}`
      if (this.$route.fullPath !== path) {
        this.$router.push(path)
      }
    },
    updateVariableNavHeightDesktop() {
      setTimeout(() => {
        this.variableDesktopNavHeight = this.$refs.expansionPanelDesktop.$el.offsetHeight
      }, this.navShowSpeed)
    },
  },
}
</script>

<style lang="scss">
.cwp-header {
  position: relative;
  z-index: 1000;
  transition: background-color 500ms $easeOutQuad;
  background: var(--header-background-color);
  color: var(--header-text-color);

  @include media('<large') {
    top: 0;
    position: sticky;
  }

  &__tel-link {
    margin-left: 16px;

    @include media('<large') {
      display: none;
    }

    .cwp-link {
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: var(--heading-font-weight);
      font-family: var(--heading-font-family);
    }
  }

  &--sticky {
    @include media('>=large') {
      animation: fade-down 500ms $easeOutQuad;
      top: 0;
      position: sticky;
      background: var(--navigation-background-color);
    }
    .cwp-header__tel-link,
    .cwp-social-links {
      .cwp-link {
        color: var(--navigation-text-color);
      }
    }
    .cwp-hamburger__bar {
      background: var(--navigation-text-color);
    }
  }

  @include media('>=large') {
    &--sticky-enter,
    &--sticky-leave {
      .rv-expansion-panel__content {
        transition: none;
      }
    }
  }

  &--expanded {
    @include media('<large') {
      position: fixed;
      overflow: auto;
      height: 100vh;
      width: 100vw;
      background-color: var(--header-background-color);
      transition: background-color 500ms $easeOutQuad;
    }

    .cwp-header__bottom-wrapper > .rv-expansion-panel__content {
      overflow: visible;
    }
  }

  &__bottom-wrapper {
    display: block;

    @include media('>=large') {
      display: none;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;

    @include media('>=large') {
      align-items: stretch;
    }
  }

  &--sticky &__top {
    @include media('>=large') {
      align-items: center;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
  }

  &__logo.cwp-link {
    display: flex;
    align-items: center;
  }

  &__desktop-logo {
    display: none;
    width: 240px;

    @include media('>=large') {
      display: block;
    }
  }

  &__mobile-logo {
    display: block;
    width: 80px;
    margin: 0 8px;

    @include media('>=large') {
      display: none;
    }
  }

  &__link-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__sub-logo-container {
    display: none;

    @include media('>=large') {
      display: flex;
      margin-bottom: 16px;
      min-height: 40px;
    }
  }

  &__sub-logo {
    margin-left: 24px;
  }

  &--sticky &__sub-logo-container {
    display: none;
  }

  &__cta-container {
    display: flex;
    align-items: center;
  }

  &__mobile-tel-link {
    @include media('>=large') {
      display: none;
    }
    .cwp-icon {
      width: 24px;
    }
  }

  &__register-link {
    margin-left: 16px;

    @include media('<large') {
      display: none;
    }
  }

  &__mobile-register-link {
    text-align: center;
    padding: 0 0 16px 0;

    @include media('>=large') {
      display: none;
    }
  }

  &__bottom {
    z-index: 9;
    padding: 32px 0;
    color: var(--header-text-color);

    @include media('>=large') {
      color: var(--navigation-text-color);
      padding: 0;
      background: var(--navigation-background-color);
    }
    &--desktop {
      display: none;

      @include media('>=large') {
        display: block;
      }
    }
  }

  &__bottom-inner {
    display: flex;
    flex-direction: column;

    @include media('>=large') {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding: 0 0 16px 0;
    position: relative;

    @include media('>=large') {
      padding: 0 0 0 16px;
    }
  }

  .cwp-search-button {
    display: none;

    @include media('>=large') {
      display: block;
    }
  }

  &__search-icon {
    margin-left: 8px;
    display: none;

    @include media('>=large') {
      display: inline-block;
      width: 24px;
      right: 48px;
      top: 0;
      position: absolute;
      z-index: 2;
    }
  }

  &__search-close {
    margin-left: 8px;
    display: none;

    @include media('>=large') {
      display: inline-block;
      width: 16px;
    }
  }

  &__search-input {
    flex-grow: 1;
    transition: all 300ms $easeOutQuad;

    @include media('>=large') {
      position: absolute;
      top: -8px;
      right: 32px;
      opacity: 0;
      pointer-events: none;
      width: 536px;
      box-shadow: 0 30px 25px -25px rgba(0, 0, 0, 0.5);
    }

    .rv-input .rv-form__field {
      margin-bottom: 0;
      height: 38px;
      transition: all 300ms $easeOutQuad;
    }

    .rv-input .rv-form__label {
      @include sr-only();
    }
  }

  &__search-submit {
    margin-left: 8px;

    @include media('>=large') {
      display: none;
    }
  }

  &--search-active &__search-input {
    @include media('>=large') {
      top: -20px;
      opacity: 1;
      pointer-events: auto;

      .rv-input .rv-form__field {
        height: 64px;
        border: 1px var(--primary-tint-light-color) solid;
      }
    }
  }

  .rv-primary-nav__link {
    transition: text-shadow 300ms $easeOutQuad, color 300ms $easeOutQuad;
  }

  .rv-expansion-panel__chevron:before,
  .rv-expansion-panel__chevron:after {
    transition: all 300ms $easeOutQuad;
  }

  &--search-active {
    @include media('>=large') {
      .rv-primary-nav__link {
        color: transparent;
        text-shadow: 0 0 2px var(--navigation-text-color);
      }

      .rv-expansion-panel__chevron:before,
      .rv-expansion-panel__chevron:after {
        opacity: 0.6;
        box-shadow: 0 0 2px var(--navigation-text-color);
      }
    }
  }

  &__navigation-menu {
    @include media('>=large') {
      display: none;
      margin-left: 16px;
    }

    .cwp-icon {
      width: 24px;
    }
  }

  &--sticky &__navigation-menu {
    display: block;
  }

  &--slim &__navigation-menu {
    @include media('>=large') {
      display: none;
    }
  }

  &__social-links {
    display: none;

    @include media('>=large') {
      display: block;
    }
  }

  &__mobile-social-links {
    display: flex;
    justify-content: center;
    padding: 0 0 16px 0;

    @include media('>=large') {
      display: none;
    }
  }
}

@keyframes fade-down {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
