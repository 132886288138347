export const state = () => ({
  components: [],
  page: {
    title: '',
    seo: {
      description: '',
      canonicalUrl: '',
      'og:description': '',
      'og:image': '',
      'og:title': '',
      'og:type': '',
      'og:url': '',
    },
    meta: [],
    linkedPageUrl: '',
  },
  pageSettings: {
    primaryNavigation: [],
    general: {},
    integrations: {},
  },
  api: process.env.VUE_APP_API_URL,
  youtubeApi: false,
  csrf: '',
  pageSettingsReady: false,
  componentsReady: false,
})

// getters
export const getters = {
  componentIndex: state => ({ className, id }) => {
    const lookup = state.components
      .filter(component => component.className === className)
      .map(component => component.id)
    return lookup.indexOf(id)
  },
}

// actions
export const actions = {
  updatePage({ commit }, payload) {
    commit('setPage', payload)
  },
  updateComponents({ commit }, payload) {
    commit('setComponents', payload)
  },
  updatePageSettings({ commit }, payload) {
    commit('setPageSettings', payload)
  },
  updateSetting({ commit }, payload) {
    commit('setSetting', payload)
  },
  updateComponentsHeaders({ commit }, payload) {
    commit('setHeaders', payload)
  },
}

// mutations
export const mutations = {
  setPage(state, payload) {
    state.page = payload
  },
  setComponents(state, payload) {
    state.components = payload
  },
  setPageSettings(state, payload) {
    state.pageSettings = payload
  },
  setSetting(state, payload) {
    Object.assign(state, payload)
  },
  setHeaders(state, payload) {
    state.csrf = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
