export const state = () => ({
  log: {
    referrerUrl: '',
    currentUrl: '',
    title: '',
  },
  pageType: '',
  pageCode: 200,
  pageLocation: '',
  pageSettingsCode: 200,
  componentsCode: 200,
  pageFacebookPixelTracking: false,
  breakpoints: {
    small: true,
    medium: true,
    large: true,
    xlarge: true,
  },
  integrations: {
    googleRecaptchaEnabled: false,
    googleRecaptchaSiteKey: '',
    gtmContainerId: '',
    facebookPixelSnippet: '',
  },
  modal: {
    top: '0px',
    open: false,
  },
})

// getters
export const getters = {}

// actions
export const actions = {
  updateReferrerUrl({ commit }, payload) {
    commit('setReferrerUrl', payload)
  },
  updateCurrentUrl({ commit }, payload) {
    commit('setCurrentUrl', payload)
  },
  updateTitle({ commit }, payload) {
    commit('setTitle', payload)
  },
  updatePageType({ commit }, payload) {
    commit('setPageType', payload)
  },
  updatePageCode({ commit }, payload) {
    commit('setPageCode', payload)
  },
  updatePageSettingsCode({ commit }, payload) {
    commit('setPageSettingsCode', payload)
  },
  updateComponentsCode({ commit }, payload) {
    commit('setComponentsCode', payload)
  },
  updatePagePixelTracking({ commit }, payload) {
    commit('setPageFacebookPixelTracking', payload)
  },
  updateIntegrations({ commit }, payload) {
    commit('setIntegrations', payload)
  },
  updateBreakpoints({ commit }) {
    const width = window.innerWidth
    commit('setBreakpoints', {
      small: true,
      medium: width >= 640,
      large: width >= 1024,
      xlarge: width >= 1200,
    })
  },
  updateModal({ commit }, payload) {
    commit('setModal', payload)
  },
}

// mutations
export const mutations = {
  setReferrerUrl(state, payload) {
    state.log.referrerUrl = payload
  },
  setCurrentUrl(state, payload) {
    state.log.currentUrl = payload
  },
  setTitle(state, payload) {
    state.log.title = payload
  },
  setPageType(state, payload) {
    if (Array.isArray(payload)) {
      state.pageType = payload[0]
      state.pageCode = payload[1]
      state.pageLocation = payload[2]
    } else {
      state.pageType = payload
    }
  },
  setPageCode(state, payload) {
    state.pageCode = payload
  },
  setPageSettingsCode(state, payload) {
    state.pageSettingsCode = payload
  },
  setComponentsCode(state, payload) {
    state.componentsCode = payload
  },
  setPageFacebookPixelTracking(state, bool) {
    state.pageFacebookPixelTracking = bool
  },
  setIntegrations(state, payload) {
    state.integrations = payload
  },
  setBreakpoints(state, payload) {
    state.breakpoints = payload
  },
  setModal(state, payload) {
    state.modal = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
