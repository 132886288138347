import camelToKebab from 'camel-to-kebab'
import { format } from 'date-fns'

/**
 * Compares two values using the passed comparisonType
 * @function
 * @name compare
 * @param {string} comparisonType Indicates comparison operation to perform
 * @param {string} value1 First value to compare
 * @param {string} value2 Second value to compare
 * @returns {boolean}
 */
export function compare(comparisonType, value1, value2) {
  switch (comparisonType) {
    case 'lessThan':
      return value1 < value2
    case 'lessThanOrEqual':
      return value1 <= value2
    case 'greaterThan':
      return value1 > value2
    case 'greaterThanOrEqual':
      return value1 >= value2
    case 'equal':
      return value1 === value2
    case 'notEqual':
      return value1 !== value2
    case 'any':
      // A string is treated as a CSV list
      if (typeof value2 === 'string') {
        value2 = value2.replace(', ', ',').split(',')
      }
      return value2.includes(value1)
    case 'month':
      return compareMonth(value1, value2)
    default:
      console.error('Invalid comparisonType: ' + comparisonType)
  }
}

/**
 * slow down reptitive calls to apis or functions
 * @function
 * @name debounce
 * @param {function} fn function to fire once timer has expired
 * @param {number} time time in ms to fire function
 * @return {function}
 * @example
 * searchRequest: debounce(function() {
 *   Kentico.getSiteSearchResults(this.search)
 *     .then(response => {
 *       if (response.status === 200) {
 *         this.results = response.data
 *       }
 *     })
 * }, 300),
 */
export const debounce = (fn, time) => {
  let timeout
  let context = this
  return () => {
    const functionCall = () => fn.apply(context, arguments)
    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

/**
 * Returns a function, that, when invoked, will only be triggered at most once during a given window of time.
 * @name throttle
 * @param {*} func function to throttle
 * @param {*} wait minium interval between function calls
 * @param {*} options whether to call function at start and end of timeout
 */
export const throttle = (func, wait, options = {}) => {
  let context, args, result
  let timeout = null
  let previous = 0
  let later = function () {
    previous = options.leading === false ? 0 : Date.now()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) context = args = null
  }
  return function () {
    var now = Date.now()
    if (!previous && options.leading === false) previous = now
    var remaining = wait - (now - previous)
    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}

export const createCssVar = (variable, value) => {
  return `--${variable}: ${value};\n`
}

/**
 * @name createCssCustomProps
 * @function
 * @param {object} obj key value pairs to be converted into css variables
 * @param {*} selector scoped selector for variables, defaults to global with :root
 * @return {string}
 */
export const createCssCustomProps = (obj, buttonStyle = 'solid', selector = ':root') => {
  if (!obj) return
  const colorVariations = ['primaryColor', 'secondaryColor', 'tertiaryColor', 'bodyTextColor']
  const remappedValues = [
    'primary',
    'primary-tint-light',
    'primary-shade-dark',
    'secondary',
    'secondary-tint-light',
    'secondary-shade-dark',
    'tertiary',
    'tertiary-tint-light',
    'tertiary-shade-dark',
    'white',
    'black',
    'light-grey',
    'grey',
  ]
  let cssVars = ''
  Object.keys(obj).forEach(key => {
    const variable = camelToKebab(key)
    const value = obj[key]
    if (colorVariations.includes(key)) {
      cssVars += createCssVar(`${variable.replace('-color', '')}-tint-light-color`, alterColor(value, 60))
      cssVars += createCssVar(`${variable.replace('-color', '')}-shade-dark-color`, alterColor(value, -30))
    }
    if (key === 'defaultButtonColor' || key === 'buttonColor' || key === 'buttonColor1' || key === 'buttonColor2') {
      let suffix = ''
      if (key === 'buttonColor1') suffix = '-1'
      if (key === 'buttonColor2') suffix = '-2'
      if (buttonStyle === 'hollow') {
        cssVars += createCssVar(`button-color${suffix}`, `var(--${value}-color)`)
        cssVars += createCssVar(`button-border-color${suffix}`, `var(--${value}-color)`)
        cssVars += createCssVar(`button-background-color${suffix}`, 'transparent')
        cssVars += createCssVar(`button-hover-color${suffix}`, `var(--${value}-button-text-color)`)
        cssVars += createCssVar(`button-hover-background-color${suffix}`, `var(--${value}-color)`)
      } else {
        cssVars += createCssVar(`button-color${suffix}`, `var(--${value}-button-text-color)`)
        cssVars += createCssVar(`button-border-color${suffix}`, `var(--${value}-color)`)
        cssVars += createCssVar(`button-background-color${suffix}`, `var(--${value}-color)`)
        cssVars += createCssVar(`button-hover-color${suffix}`, `var(--${value}-color)`)
        cssVars += createCssVar(`button-hover-background-color${suffix}`, 'transparent')
      }
    } else if (key === 'defaultComponentBackgroundColor') {
      cssVars += createCssVar('background-color', `var(--${value}-color)`)
    } else if (remappedValues.includes(value)) {
      cssVars += createCssVar(variable, `var(--${value}-color)`)
    } else if (key === 'headingFont') {
      cssVars += createCssVar(variable, `'${value}', sans-serif`)
    } else if (key === 'bodyTextFont') {
      cssVars += createCssVar(variable, `'${value}', sans-serif`)
    } else {
      cssVars += createCssVar(variable, value)
    }
    return cssVars
  })
  return `${selector} { ${cssVars} }`
}

export const alterColor = (color, percent) => {
  if (color[0] === '#') {
    color = color.slice(1)
  }

  const num = parseInt(color, 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt

  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`
}

/**
 * @name utilityClassGenerator
 * @description generates class modifiers defined by Kentico
 * @param {String} baseClass
 * @param {Object} obj
 */
export const utilityClassGenerator = (baseClass, obj) => {
  if (!obj && typeof obj === 'object') return console.warn('container object not passed')
  let cssClasses = []
  const availableClasses = [
    'componentType',
    'imageAlignment',
    'numberOfCardsPerRow',
    'blockType',
    'textWidth',
    'textAlignment',
    'layout',
    'displayType',
    'columnWidth',
    'imageSize',
  ]
  Object.keys(obj).forEach(key => {
    if (availableClasses.includes(key)) {
      cssClasses.push(`${camelToKebab(baseClass)}__${camelToKebab(key)}--${obj[key]}`)
    }
  })
  return cssClasses
}

/**
 * @name watchOverflow
 * @description Adds css classes based on element scroll position.
 * @returns {Object} - watchOverflow with destroy method.
 * @param {string} id of the overflow container.
 */
export function watchOverflow(element) {
  if (!element) return console.warn('No selector or element passed to responsive overflow function.')

  element = typeof element === 'string' ? document.querySelector(element) : element

  const baseClass = 'watch-overflow'

  element = typeof element === 'string' ? document.querySelector(element) : element

  if (!element) return console.warn('Failed to initialise responsive overflow element.')

  init()

  let scroll
  let overflow
  let wrapper
  let inner

  /**
   * Initialises the overflow container.
   */
  function init() {
    if (element.classList.contains(`${baseClass}__table`)) {
      // Re-use existing elements
      inner = element.parentElement
      wrapper = inner.parentElement
    } else {
      // Create new elements
      wrapper = document.createElement('div')
      wrapper.classList.add(baseClass)
      inner = document.createElement('div')
      inner.classList.add(`${baseClass}__inner`)
      element.classList.add(`${baseClass}__table`)
      wrapper.appendChild(inner)
      element.parentNode.insertBefore(wrapper, element)
      inner.appendChild(element)
    }
    setClasses('add')
    update()
    window.addEventListener('resize', update)
    inner.addEventListener('scroll', update)
  }

  /**
   * Calculates scroll offset as a percentage, adds or removes classes depending on scroll position
   */
  function update() {
    let offset = inner.scrollWidth - inner.clientWidth
    if (offset === 0) {
      scroll = 0
      overflow = false
    } else {
      scroll = (100 * inner.scrollLeft) / offset
      overflow = true
    }
    if (overflow) {
      setClasses('add', 'has-overflow')
      if (scroll < 1) {
        setClasses('add', 'start')
      } else {
        setClasses('remove', 'start')
      }
      if (scroll > 99) {
        setClasses('add', 'end')
      } else {
        setClasses('remove', 'end')
      }
    } else {
      setClasses('remove', 'start')
      setClasses('remove', 'end')
      setClasses('remove', 'has-overflow')
    }
  }

  /**
   * Updates classes on wrapper
   */
  function setClasses(method, modifier) {
    wrapper.classList[method](modifier ? `${baseClass}--${modifier}` : baseClass)
  }

  /**
   * Destroys the responsive overflow wrapper.
   *
   * @public
   */
  function destroy() {
    element.removeEventListener('scroll', update)
    window.removeEventListener('resize', update)
    setClasses('remove')
    setClasses('remove', 'start')
    setClasses('remove', 'end')
    setClasses('remove', 'has-overflow')
  }

  return {
    update,
    destroy,
  }
}

/**
 * Formats date to specified format
 * @function
 * @name formatDate
 * @param {String} date
 * @param {Object} format
 * @returns {String} Formatted date
 */
export const formatDate = (date, pattern = 'dd MMMM, yyyy') => {
  return format(new Date(date), pattern)
}

/**
 * Formats time to specified format
 * @function
 * @name formatTime
 * @param {String} time
 * @param {Object} format
 * @returns {String} Formatted time
 */
export const formatTime = (time, hour12 = true) => {
  return new Date('1970-01-01T' + time + 'Z').toLocaleTimeString('en-AU', {
    timeZone: 'UTC',
    hour12,
    hour: 'numeric',
    minute: 'numeric',
  })
}

/**
 * Wraps table elements in wrappers and adds watchers which add and remove css classes based on scroll position
 * @function
 * @name responsiveTables
 * @param {HTMLElement} el
 * @returns {String} Formatted HTML
 */
export const responsiveTables = el => {
  const tables = el.querySelectorAll('table')
  const controllers = []
  if (tables) {
    tables.forEach(table => {
      const overflowContainer = watchOverflow(table)
      if (overflowContainer) controllers.push(overflowContainer)
    })
  }
  return controllers
}

/**
 * Wraps iframe elements in wrappers to allow absolutely positioned iframes with fixed aspect ratios
 * @function
 * @name responsiveEmbed
 * @param {HTMLElement} el
 * @returns {String} Formatted HTML
 */
export const responsiveEmbed = el => {
  const iframes = el.querySelectorAll('iframe')
  if (iframes) {
    iframes.forEach(iframe => {
      if (!iframe.parentElement.classList.contains('responsive-embed')) {
        const wrapper = document.createElement('div')
        wrapper.classList.add('responsive-embed')
        iframe.parentNode.insertBefore(wrapper, iframe)
        wrapper.appendChild(iframe)
      }
    })
  }
}

/**
 * Deep clones an array or object
 * @function
 * @name deepClone
 * @param {Array|Object} input
 * @returns {Array|Object} cloned output
 */
export const deepClone = input => {
  return JSON.parse(JSON.stringify(input))
}

/**
 * Scrolls an object into view
 * @function
 * @name scrollTo
 * @param {element} string selector or element to scroll to
 * @param {block} string Defines vertical alignment, defaults to start
 */
export const scrollTo = (element, offset = 0) => {
  const target = typeof element === 'string' ? document.querySelector(element) : element
  if (!target) {
    if (process.env.NODE_ENV !== 'production') console.warn('Could not find element:', element)
    return
  }
  // Account for Doghouse header height if present
  const header = document.querySelector('.cwp-header')
  const headerHeight = header ? header.offsetHeight : 0

  const position = target.getBoundingClientRect().top + window.pageYOffset - headerHeight + offset
  window.scrollTo({ top: position, behavior: 'smooth' })
}
