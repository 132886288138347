import Vue from 'vue'

/**
 * Plugin: $gtmTrack
 */

Vue.prototype.$gtmTrack = {
  $gtm: null,
  init(GtmPlugin, id) {
    Vue.use(GtmPlugin, { id }) // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    this.$gtm = Vue.prototype.$gtm // Only initialised if Vue.use(..) did not throw
  },
  formSuccess(formName, formValues) {
    if (!this.$gtm) return
    const additionalData = {}
    if (formName === 'GeneralEnquiry') {
      if (formValues.WhichBestDescribesYou) additionalData.customer_type = formValues.WhichBestDescribesYou
      if (formValues.WhatAreYouInterestedIn) additionalData.interest = formValues.WhatAreYouInterestedIn
    }
    this.$gtm.trackEvent({
      event: 'formSuccess',
      form_name: formName,
      ...additionalData,
    })
  },
  event(data) {
    if (!this.$gtm) return
    this.$gtm.trackEvent(data)
  },
}
