import { compare } from '../../functions/utils'
import { extend } from 'vee-validate'

function buildRule(formName, fields) {
  const compareFields = fields.filter(x => x.validation && x.validation.compare)
  compareFields.forEach(field => {
    const { fieldName } = field.validation.compare

    const targetFields = [{ name: fieldName, isTarget: true }]
    const compareName = `compare_${formName}_${field.name}`

    extend(compareName, {
      computesRequired: true,
      params: [
        { name: 'multiForm', isTarget: true },
        { name: 'fieldName' },
        { name: 'comparisonType' },
        { name: 'valMsg' },
      ].concat(targetFields),
      validate: (value, values) => {
        const { fieldName, comparisonType, valMsg } = values
        let fieldValues = values
        if (
          typeof values.multiForm === 'object' &&
          typeof values.multiForm[formName] === 'object' &&
          typeof values.multiForm[formName].formValues === 'object'
        ) {
          fieldValues = values.multiForm[formName].formValues
        }
        const required = compare(comparisonType, fieldValues[field.name], fieldValues[fieldName])
        const rs = {
          valid: true,
          required,
        }

        //override message, using message as an option in extend doesn't work
        if (value && !rs.required) {
          return valMsg
        }
        return rs
      },
    })

    const compareRule = targetFields.reduce(
      (rule, x) => ({
        [x.name]: x.name,
        ...rule,
      }),
      { multiForm: 'multiForm', ...field.validation.compare }
    )

    //rename compare rule to rule per field
    field.validation[compareName] = compareRule
    delete field.validation.compare
  })
}

export default { buildRule }
export { buildRule }
