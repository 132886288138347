<template>
  <div v-show="pageSettingsReady && componentsReady" :class="classes" :style="{ top: modal.top }" class="cwp-app">
    <template v-if="pageSettingsCode === 200">
      <a class="sr-only" href="#main-content">Skip to main content</a>
      <cwp-header :sticky="sticky" />
      <div ref="trigger" class="cwp-app__trigger" />
      <main id="main-content" tabindex="-1" :class="subBrandClasses" class="cwp-app__main">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.path" :name="pageType" :data-test="pageType" :class="pageType.toLowerCase()" />
        </transition>
      </main>
      <cwp-footer />
    </template>
    <div v-else class="grid-container cwp__site-down">Site is unavailable</div>
  </div>
</template>
<script>
import CwpHeader from '@/components/CwpHeader/CwpHeader.vue'
import CwpFooter from '@/components/CwpFooter/CwpFooter.vue'
import { createCssCustomProps } from '@/functions/utils'
import Vue from 'vue'
import { mapState } from 'vuex'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Kentico from '@/api/kentico'

export default {
  name: 'Root',
  components: {
    CwpHeader,
    CwpFooter,
  },
  data() {
    return {
      hasMounted: false,
      styles: {},
      observer: null,
      sticky: false,
      observerTimeout: false,
      linked: {},
    }
  },

  computed: {
    ...mapState({
      pageType: state => state.settings.pageType,
      pageCode: state => state.settings.pageCode,
      pageSettingsCode: state => state.settings.pageSettingsCode,
      page: state => state.site.page,
      pageSettings: state => state.site.pageSettings,
      pageSettingsReady: state => state.site.pageSettingsReady,
      componentsReady: state => state.site.componentsReady,
      youtubeApi: state => state.site.youtubeApi,
      components: state => state.site.components,
      breakpoints: state => state.settings.breakpoints,
      modal: state => state.settings.modal,
    }),
    subBrandClass() {
      return `cwp__${this.$route.path.substring(1).slice(0, -1).replace(/\//g, '-')}`
    },
    classes() {
      return {
        [`button-style--${this.pageSettings?.styles?.buttonStyle}`]: true,
        'cwp-app--modal': this.modal.open,
      }
    },
    subBrandClasses() {
      return `${this.subBrandClass} button-style--${this.page?.customData?.styles?.buttonStyle ?? 'none'}`
    },
    fontImport() {
      return this.getFontImport(this.pageSettings)
    },
    fontComImport() {
      return this.getFontComImport(this.pageSettings)
    },
    subBrandFontImport() {
      return this.getFontImport(this.page?.customData)
    },
    subBrandFontComImport() {
      return this.getFontComImport(this.page?.customData)
    },
    customCssSubBrandingLinked() {
      if (this.linked?.customData?.styles !== undefined) {
        return {
          vmid: 'customCssSubBrandingLinked',
          cssText: this.linked?.customData?.styles
            ? createCssCustomProps(
                this.linked?.customData?.styles,
                this.linked?.customData?.styles?.buttonStyle,
                '.' + this.subBrandClass
              )
            : '',
        }
      } else {
        return {}
      }
    },
    metaCustomCssContainer() {
      let styles = {}
      let meta = []
      if (this.components && Array.isArray(this.components)) {
        const buttonStyle = this.page?.customData?.styles?.buttonStyle || this.pageSettings?.styles?.buttonStyle
        this.components.forEach(component => {
          const extractCssProperties = function (element) {
            const availableClasses = [
              'textColor',
              'backgroundColor',
              'descriptionTextAlignment',
              'buttonColor',
              'buttonStyle',
              'cardTextColor',
              'cardTextAlignment',
              'cardBackgroundColor',
              'buttonColor1',
              'buttonColor2',
            ]
            Object.keys(element).forEach(key => {
              if (availableClasses.includes(key)) {
                styles[`guid-${element.id}`] = {
                  ...styles[`guid-${element.id}`],
                  [key]: element[key],
                }
              }
            })
          }
          // CarouselBanners are collection of Banners, need to loop inside collection to extract styling
          if (component.carouselItems) {
            component.carouselItems.forEach(banner => {
              extractCssProperties(banner)
            })
          } else {
            extractCssProperties(component)
          }
        })
        Object.keys(styles).forEach(key => {
          meta = [
            ...meta,
            {
              vmid: `customCssContainer-${key}`,
              cssText: createCssCustomProps(styles[key], buttonStyle, `.${key}`),
            },
          ]
        })
      }
      return meta
    },
  },
  watch: {
    page(newValue, oldValue) {
      return newValue !== oldValue ? this.metaCustomCssLinked() : null
    },
  },
  mounted() {
    if (window) {
      window.mapsCallback = () => {}
    }
    const updateSticky = value => {
      this.sticky = value
    }
    this.observer = new IntersectionObserver(
      ([e]) => {
        if (this.breakpoints.large) {
          updateSticky(!!(!e.isIntersecting && e.target.clientHeight))
        }
      },
      {
        threshold: 1,
      }
    )
    const updateBreakpoints = () => {
      this.$store.dispatch('settings/updateBreakpoints')
    }
    updateBreakpoints()
    window.addEventListener('resize', updateBreakpoints)
    if (this.$refs.trigger) {
      this.observer.observe(this.$refs.trigger)
    }

    if (this.pageSettings.integrations.googleRecaptchaEnabled) {
      Vue.use(VueReCaptcha, {
        siteKey: this.pageSettings.integrations.googleRecaptchaSiteKey,
        loaderOptions: { autoHideBadge: false },
      })

      this.$recaptchaLoaded().then(() => {
        const recaptcha = document.querySelector('.g-recaptcha-response')
        recaptcha.setAttribute('aria-hidden', 'true')
        recaptcha.setAttribute('aria-label', 'recaptcha')
      })
    }

    this.$store.dispatch('site/updateSetting', { pageSettingsReady: true })
  },
  destroyed() {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    metaCustomCssLinked() {
      if (this.page?.linkedPageUrl !== undefined) {
        Kentico.getLinkedStyles(this.page?.linkedPageUrl).then(response => {
          this.$nextTick(() => {
            this.linked = response.data
          })
        })
      }
    },
    getFontImport(settings) {
      const fontType = settings?.general?.fontType || settings?.fontType
      const link = { rel: 'stylesheet', href: '' }
      if (fontType === 'Google') {
        const headingFont = (settings?.styles?.headingFont || '').split(' ').join('+')
        const bodyTextFont = (settings?.styles?.bodyTextFont || '').split(' ').join('+')
        headingFont === bodyTextFont
          ? (link.href = `https://fonts.googleapis.com/css2?family=${bodyTextFont}:wght@400;700&display=swap`)
          : (link.href = `https://fonts.googleapis.com/css2?family=${headingFont}:wght@700&family=${bodyTextFont}:wght@400;700&display=swap`)
      } else if (fontType === 'Adobe') {
        link.href = settings?.general?.fontUri ?? 'https://use.typekit.net/rxa3uwc.css'
      }
      return link
    },
    getFontComImport(settings) {
      if (settings?.general?.fontType === 'fonts.com') {
        return {
          vmid: `fontsDotCom`,
          cssText: settings?.general?.fontDotComCodeBlock,
        }
      }
      return {}
    },
  },
  metaInfo() {
    return {
      title: `${this.page?.title ?? ''}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.page.seo?.description ?? '',
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.page.seo?.['og:title'] ?? '',
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.page.seo?.['og:description'] ?? '',
        },
        { vmid: 'og:type', name: 'type', property: 'og:type', content: this.page.seo?.['og:type'] ?? '' },
        { vmid: 'og:url', name: 'url', property: 'og:url', content: this.page.seo?.['og:url'] ?? '' },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.page.seo?.['og:image'] ?? '',
        },
        { vmid: 'category', name: 'category', content: this.page?.category ?? '' },
        {
          vmid: 'robots',
          name: 'robots',
          content:
            this.pageSettings?.general?.isLive ?? typeof this.pageSettings?.general?.isLive === 'undefined'
              ? this.page?.seo?.robots ?? ''
              : 'noindex, nofollow',
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        },
        /* {
          vmid: 'twitter:site',
          name: 'twitter:site',
          content: '@INSERTTWITTERNAME',
        }, */
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: this.page.seo?.['og:image'] ?? '',
        },
        // Additional arbitrary meta tags from this.page.meta
        ...(this.page.meta?.map(({ name, content }) => ({
          vmid: name,
          name,
          content,
        })) ?? []),
      ],
      script: [
        this.youtubeApi
          ? {
              id: 'youtube-api',
              src: 'https://www.youtube.com/iframe_api',
            }
          : {},
        {
          id: 'maps-api',
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAP_KEY}&libraries=places&callback=mapsCallback`,
        },
      ],
      link: [
        { rel: 'canonical', href: this.page.seo?.canonicalUrl ?? '' },
        { rel: 'icon', href: this.pageSettings?.general?.favicon ?? '' },
        { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
        this.fontImport,
        this.subBrandFontImport,
      ],
      style: [
        this.fontComImport,
        this.subBrandFontComImport,
        {
          vmid: 'customCss',
          cssText: createCssCustomProps(this.pageSettings?.styles, this.pageSettings?.styles?.buttonStyle),
        },
        ...this.metaCustomCssContainer,
        {
          vmid: 'customCssSubBranding',
          cssText: this.page?.customData?.styles
            ? createCssCustomProps(
                this.page?.customData?.styles,
                this.page?.customData?.styles?.buttonStyle,
                '.' + this.subBrandClass
              )
            : '',
        },
        this.customCssSubBrandingLinked,
      ],
    }
  },
}
</script>

<style lang="scss">
.cwp-app {
  &__trigger {
    margin-top: -60px;
    height: 60px;
  }

  &--modal {
    position: fixed;
    width: 100%;
    max-width: 100vw;
  }

  &__main {
    position: relative;
  }
}
</style>
