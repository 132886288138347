<template>
  <footer v-if="pageSettings.footer" class="cwp-footer">
    <div v-if="!page.isSlimPage" class="cwp-footer__top grid-container">
      <div
        v-if="pageSettings.footer.quickLinks && pageSettings.footer.quickLinks.length"
        class="cwp-footer__quick-links"
      >
        <h4 class="cwp-footer__section-title">Quick Links</h4>
        <cwp-link
          v-for="(link, i) in pageSettings.footer.quickLinks"
          :key="i"
          class="cwp-footer__quick-link"
          :to="link.url"
          >{{ link.name }}</cwp-link
        >
      </div>

      <div
        v-if="pageSettings.projectDetails.address || pageSettings.footer.openingHours"
        class="cwp-footer__location-container"
      >
        <h4 class="cwp-footer__section-title">Visit Us</h4>
        <component
          :is="mapLink ? 'cwp-link' : 'div'"
          :href="mapLink ? mapLink : undefined"
          :target="mapLink ? mapLink : undefined"
          class="cwp-footer__address-link"
        >
          <span v-if="pageSettings.projectDetails.address">{{ pageSettings.projectDetails.address }}, </span>
          <span v-if="pageSettings.projectDetails.suburb">{{ pageSettings.projectDetails.suburb }}, </span>
          <span v-if="pageSettings.projectDetails.state"
            >{{ pageSettings.projectDetails.state }} {{ pageSettings.projectDetails.postcode }}</span
          >
        </component>
        <div v-if="pageSettings.footer.openingHours" v-html="pageSettings.footer.openingHours" />
      </div>

      <div
        v-if="pageSettings.projectDetails.phoneNumber || pageSettings.projectDetails.emailAddress"
        class="cwp-footer__contact-container"
      >
        <h4 class="cwp-footer__section-title">Contact Us</h4>
        <cwp-link
          v-if="pageSettings.projectDetails.phoneNumber"
          class="cwp-footer__tel-link"
          :href="`tel:${pageSettings.projectDetails.phoneNumber}`"
          @click="$fbq.track('Contact')"
          >{{ pageSettings.projectDetails.phoneNumber }}</cwp-link
        ><br />
        <cwp-link
          v-if="pageSettings.projectDetails.emailAddress"
          class="cwp-footer__email-link"
          :href="`mailto:${pageSettings.projectDetails.emailAddress}`"
          @click="$fbq.track('Contact')"
          >{{ pageSettings.projectDetails.emailTextLink || pageSettings.projectDetails.emailAddress }}</cwp-link
        >
      </div>
      <div v-if="pageSettings.integrations.footerShowSocialIcons" class="cwp-footer__social-links">
        <cwp-social-links />
      </div>
    </div>
    <div class="cwp-footer__bottom grid-container">
      <div v-if="pageSettings.footer.copyrightText" class="cwp-footer__copyright">
        {{ pageSettings.footer.copyrightText }}
      </div>
      <div class="cwp-footer__nav">
        <template v-for="i in 4">
          <cwp-link
            v-if="pageSettings.footer[`link${i}Text`]"
            :key="i"
            class="cwp-footer__nav-link"
            :to="pageSettings.footer[`link${i}Url`]"
            target="_blank"
            >{{ pageSettings.footer[`link${i}Text`] }}</cwp-link
          >
        </template>
      </div>
      <div class="cwp-footer__sub-logo-container">
        <div v-if="pageSettings.footer.jointVenturePartnerLogo" class="cwp-footer__sub-logo">
          <cwp-link
            :href="pageSettings.footer.jointVenturePartnerLogoUrl"
            target="_blank"
            :deactivated="deactivateLogoLink"
          >
            <img :src="pageSettings.footer.jointVenturePartnerLogo" alt="Joint venture partner logo" />
          </cwp-link>
        </div>
        <div v-if="pageSettings.footer.logoStyle" class="cwp-footer__sub-logo">
          <cwp-link href="https://www.cedarwoods.com.au/" target="_blank" :deactivated="deactivateLogoLink">
            <img :src="`${assetPath}images/cw-logo-${pageSettings.footer.logoStyle}.svg`" alt="Cedar Woods Logo" />
          </cwp-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CwpSocialLinks from '@/components/CwpSocialLinks/CwpSocialLinks.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import { mapState } from 'vuex'
export default {
  name: 'CwpFooter',
  components: {
    CwpSocialLinks,
    CwpLink,
  },
  data() {
    return {
      assetPath: process.env.VUE_APP_ASSET_PATH,
    }
  },
  computed: {
    ...mapState({
      pageSettings: state => state.site.pageSettings,
      page: state => state.site.page,
    }),
    mapLink() {
      return this.pageSettings.projectDetails.latitude && this.pageSettings.projectDetails.longitude
        ? `https://www.google.com/maps/search/?api=1&query=${this.pageSettings.projectDetails.latitude},${this.pageSettings.projectDetails.longitude}`
        : ''
    },
    deactivateLogoLink() {
      return this.page.customData?.displayOptions.disableLogoLinks
    },
  },
}
</script>

<style lang="scss">
.cwp-footer {
  color: var(--footer-text-color);
  background-color: var(--footer-background-color);
  text-align: center;

  @include media('>=large') {
    text-align: left;
  }

  .cwp-link {
    color: var(--footer-text-color);
    text-decoration: none;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 32px;

    @include media('>=large') {
      padding-top: 48px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &:after {
      content: '';
      display: block;
      background: currentColor;
      margin-top: 32px;
      width: 100%;
      height: 1px;
    }
  }

  &__quick-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media('<large') {
      margin-bottom: 32px;
    }

    .cwp-link:not(:last-child) {
      display: inline-block;
      margin-bottom: 16px;
    }
  }

  &__location-container {
    @include media('<large') {
      margin-bottom: 32px;
    }
    p {
      margin: 16px 0;
    }
  }

  &__contact-container {
    @include media('<large') {
      margin-bottom: 32px;
    }

    .cwp-link:not(:last-child) {
      display: inline-block;
      margin-bottom: 16px;
    }
  }

  &__social-links {
    display: flex;
    justify-content: center;

    @include media('>=large') {
      padding-top: 8px;
    }
  }

  &__section-title {
    color: currentColor;
  }

  &__bottom {
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    @include media('>=large') {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 24px;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    @include media('>=medium') {
      flex-direction: row;
      margin-left: -16px;
      margin-right: -16px;
    }

    @include media('>=large') {
      margin-bottom: 0;
    }

    // stylelint-disable-next-line
    .cwp-link {
      display: inline-block;
      margin: 8px 16px;
    }
  }

  &__copyright {
    margin: 8px 0;
  }

  &__sub-logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__sub-logo {
    max-width: calc(50vw - 24px);

    &:not(:first-child) {
      margin-left: 24px;
    }

    img {
      max-width: 100%;
    }
  }

  h4 {
    margin: 0 0 16px;
  }
}
</style>
