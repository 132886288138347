import Vue from 'vue'
import store from '@/store/store'

let fbq = null

function setup() {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = fbq = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
}

const types = {
  std: ['PageView', 'Lead', 'Contact'],
  custom: ['PDF Download'],
}

/**
 * Plugin: $fbq
 * Facebook pixel tracking
 * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
 *
 * Disabled/enabled per page
 * Page endpoint (/global/mvc/api/page/current) should return trackPixelEnabled = true
 */

Vue.prototype.$fbq = {
  enabled() {
    return store.state.settings.pageFacebookPixelTracking
  },
  init(pixelId) {
    if (!pixelId) return
    setup()
    fbq.disablePushState = false // do not auto-push PageView on history change event
    fbq('init', pixelId)
  },
  track(eventType) {
    if (!fbq || !eventType || !this.enabled()) return
    const trackType = types.std.includes(eventType)
      ? 'track'
      : types.custom.includes(eventType)
      ? 'trackCustom'
      : undefined
    if (!trackType) return
    fbq(trackType, eventType)
  },
}
